import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  claim?: string
  cta?: ButtonProps
  items: ItemProps[]
  title?: string
}

export const ServicesList = memo(function ServicesList({
  claim,
  cta,
  items,
  title,
}: Props) {
  if (!items) {
    return null
  }
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        {claim ? (
          <FadeInUp>
            <Claim>{claim}</Claim>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        <Items>
          {items.map((item, index) => (
            <Item key={index} {...item} />
          ))}
        </Items>

        {cta ? (
          <FadeInUp>
            <StyledButton {...cta} />
          </FadeInUp>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 11.125vw 0;

  @media (max-width: 1199px) {
    margin: 5rem 1.5625rem 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 97.75rem;
  margin: 0 auto;
`

const Claim = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: bold;
  font-size: 0.625rem;
  letter-spacing: 0.1em;
  line-height: 1.6875rem;
  text-align: center;
  text-transform: uppercase;
`

const Title = styled.h2`
  max-width: 43.75rem;
  margin-bottom: 5rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 2.8125rem;
  letter-spacing: 0.02em;
  line-height: 3.4375rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    margin-bottom: 1.875rem;
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }
`

const Items = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(100% + 1.25rem);
  margin-left: -1.25rem;
  margin-bottom: -1.25rem;

  @media (max-width: 992px) {
    width: 100%;
    margin-left: 0;
  }
`

const StyledButton = styled(Button)`
  margin-top: 4.375rem;

  @media (max-width: 1199px) {
    margin-top: 2.8125rem;
  }
`
