import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'
import { useInView } from 'react-intersection-observer'

interface ImagesProps {
  image?: ImageProps
}

export interface Props {
  images: ImagesProps[]
  subtitle?: string
  title?: string
}

export const HeroSlider = memo(function HeroSlider({
  images,
  subtitle,
  title,
}: Props) {
  if (!images) {
    return null
  }
  if (images?.length < 1) {
    return null
  }

  const [animObjectRef, isAnimObjectVisible] = useInView({ triggerOnce: true })
  const [opacities, setOpacities] = useState<number[]>([])

  let timeout: any
  function nextTimeout() {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      instanceRef.current?.next()
    }, 5000)
  }

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: images.length > 1 ? true : false,
    slides: images.length,
    drag: false,
    detailsChanged(s) {
      nextTimeout()
      const new_opacities = s.track.details.slides.map((slide) => slide.portion)
      setOpacities(new_opacities)
    },
  })

  return (
    <Container>
      <Slider ref={sliderRef} className="keen-slider fader">
        {images.map((item, index) => (
          <Slide
            className="fader__slide"
            key={index}
            style={{ opacity: opacities[index] }}
          >
            <StyledImage {...item.image} />
          </Slide>
        ))}
      </Slider>
      <Wrapper
        row={false}
        dial={5}
        ref={animObjectRef}
        className={isAnimObjectVisible ? 'visible' : undefined}
      >
        {title ? <Title>{title}</Title> : null}
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  width: 100%;
  height: 100vh;
`

const Slider = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`

const Slide = styled.div``

const StyledImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.15;
  } */
`

const Wrapper = styled(FlexBox)`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 3.125rem;
  padding: 0 1.5625rem;
  opacity: 0;
  transform: translateY(-50%);
  transition: 0.6s 0.2s ease-in-out;

  &.visible {
    opacity: 1;
    transform: translateY(-50%);
  }
`

const Title = styled.h1`
  max-width: 45rem;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 3.75rem;
  letter-spacing: 0.02em;
  line-height: 1.15;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 2.8125rem;
    line-height: 3.4375rem;
  }

  @media (max-width: 768px) {
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }
`

const Subtitle = styled.div`
  margin-top: 2.1875rem;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: bold;
  font-size: 0.875rem;
  letter-spacing: 0.1em;
  line-height: 1.6875rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    margin-top: 1.5625rem;
  }
`
