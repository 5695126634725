import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  claim?: string
  cta?: ButtonProps
  offers: ItemProps[]
  title?: string
}

export const OffersSlider = memo(function OffersSlider({
  claim,
  cta,
  offers,
  title,
}: Props) {
  if (offers.length < 1) {
    return null
  }

  const [sliderRef, galleryRef] = useKeenSlider({
    loop: offers.length > 1 ? true : false,
    slides: {
      perView: 1,
    },
  })

  return (
    <Container>
      <Wrapper>
        {claim ? (
          <FadeInUp>
            <Claim>{claim}</Claim>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        <SliderContainer>
          <Slider ref={sliderRef} className="keen-slider">
            {offers.map((item, index) => (
              <Item className="keen-slider__slide" key={index} {...item} />
            ))}
          </Slider>
          {offers.length > 1 ? (
            <Arrows row space="between">
              <Arrow
                className="transparent"
                onClick={(e) =>
                  e.stopPropagation() || galleryRef.current?.prev()
                }
              />
              <Arrow
                className="transparent"
                direction="R"
                onClick={(e) =>
                  e.stopPropagation() || galleryRef.current?.next()
                }
              />
            </Arrows>
          ) : null}
        </SliderContainer>

        {cta ? (
          <FadeInUp>
            <StyledButton {...cta} />
          </FadeInUp>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 11.125vw 0;

  @media (max-width: 1199px) {
    margin: 5rem 1.5625rem 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 97.75rem;
  margin: 0 auto;
`

const Claim = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: bold;
  font-size: 0.625rem;
  letter-spacing: 0.1em;
  line-height: 1.6875rem;
  text-align: center;
  text-transform: uppercase;
`

const Title = styled.h2`
  max-width: 43.75rem;
  margin-bottom: 5rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 2.8125rem;
  letter-spacing: 0.02em;
  line-height: 3.4375rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    margin-bottom: 1.875rem;
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }
`

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
`

const Slider = styled.div``

const StyledButton = styled(Button)`
  margin-top: 4.375rem;

  @media (max-width: 1199px) {
    margin-top: 2.8125rem;
  }
`

const Arrows = styled(FlexBox)`
  position: absolute;
  top: 50%;
  right: -7.5vw;
  left: -7.5vw;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 3;

  @media (max-width: 1199px) {
    display: none;
  }
`
