import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

interface ImagesProps {
  image?: ImageProps
}

export interface Props {
  label?: string
  images: ImagesProps[]
  title?: string
}

export const InstagramSlider = memo(function InstagramSlider({
  label,
  images,
  title,
}: Props) {
  if (!images) {
    return null
  }
  if (images?.length < 1) {
    return null
  }

  const [sliderRef] = useKeenSlider({
    loop: images.length > 1 ? true : false,
    drag: true,
    slides: {
      perView: 'auto',
      spacing: 22,
    },
  })

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Heading>
          <Wrapper>
            {label ? (
              <FadeInUp>
                <Label>{label}</Label>
              </FadeInUp>
            ) : null}
            {title ? (
              <FadeInUp>
                <Title>{title}</Title>
              </FadeInUp>
            ) : null}
          </Wrapper>
        </Heading>

        <FadeInUp>
          <SliderContainer>
            <Media greaterThanOrEqual="desktopSmall">
              <Slider>
                <Slides>
                  {images.map((item, index) => (
                    <Slide key={index}>
                      <LazyLoadComponent key={index}>
                        <StyledImage {...item.image} />
                      </LazyLoadComponent>
                    </Slide>
                  ))}
                </Slides>
              </Slider>
            </Media>
            <Media lessThan="desktopSmall">
              <Slider ref={sliderRef} className="keen-slider">
                {images.map((item, index) => (
                  <Slide className="keen-slider__slide" key={index}>
                    <LazyLoadComponent key={index}>
                      <StyledImage {...item.image} />
                    </LazyLoadComponent>
                  </Slide>
                ))}
              </Slider>
            </Media>
          </SliderContainer>
        </FadeInUp>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  margin: 9.375rem 0;

  @media (max-width: 1199px) {
    margin: 5rem 0;
  }
`

const Heading = styled.div`
  margin: 0 11.125vw 5rem;

  @media (max-width: 1199px) {
    margin: 0 1.5625rem 1.875rem;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`

const Label = styled.div`
  margin-bottom: 0.625rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: bold;
  font-size: 0.625rem;
  letter-spacing: 0.1em;
  line-height: 1.6875rem;
  text-align: center;
  text-transform: uppercase;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 2.8125rem;
  letter-spacing: 0.02em;
  line-height: 3.4375rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }

  @media (max-width: 480px) {
    word-break: break-all;
  }
`

const SliderContainer = styled.div`
  margin: 0 11.125vw;

  @media (max-width: 1199px) {
    margin-left: 1.5625rem;
    margin-right: 0;
  }
`

const Slider = styled.div`
  position: relative;
  width: 100%;
  max-width: 97.75rem;
  margin: 0 auto;

  &:hover {
    @media (max-width: 1199px) {
      cursor: move;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }
  }
`

const Slides = styled.div`
  @media (min-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: -1.375rem;
    margin-bottom: -1.375rem;
  }
`

const Slide = styled.div`
  width: 100%;
  min-width: 14.0625rem;
  min-height: 14.0625rem;

  @media (min-width: 1200px) {
    width: calc(33.33% - 1.375rem);
    margin-left: 1.375rem;
    margin-bottom: 1.375rem;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  display: block;
  width: 100%;
  aspect-ratio: 1;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
    width: 1.5625rem;
    height: 1.5625rem;
    background-image: url('/icon-instagram-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 2;
  }
`
