import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  icon?: string
  image?: ImageProps
  label?: string
}

export const Item = memo(function Item({ icon, image, label }: Props) {
  if (!image) {
    return null
  }

  return (
    <Container>
      <LazyLoadComponent>
        <StyledImage {...image} />
      </LazyLoadComponent>
      <Inner>
        {icon ? (
          <FadeInUp>
            <Icon src={icon} alt={label} width="40" height="40" />
          </FadeInUp>
        ) : null}
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
      </Inner>
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  width: calc(50% - 1.25rem);
  margin-left: 1.25rem;
  margin-bottom: 1.25rem;

  @media (max-width: 992px) {
    width: 100%;
    margin-left: 0;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  display: block;
  width: 100%;
  max-height: 31.75rem;
  aspect-ratio: 600/400;

  @media (max-width: 992px) {
    max-width: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
  }
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1.5625rem;
  z-index: 1;
`

const Icon = styled.img`
  margin-bottom: 1.25rem;
`

const Label = styled.h3`
  max-width: 12.5rem;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 1.1875rem;
  letter-spacing: 0.02em;
  line-height: 1.6875rem;
  text-align: center;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`
