import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import React, { memo } from 'react'

export interface Props {
  claim?: string
  description?: string
  title?: string
}

export const Heading = memo(function Heading({
  claim,
  description,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper>
        <Left>
          {claim ? (
            <FadeInUp>
              <Claim>{claim}</Claim>
            </FadeInUp>
          ) : null}
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
        </Left>
        <Right>
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
        </Right>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 11.125vw 0;

  @media (max-width: 1199px) {
    margin: 5rem 1.5625rem 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  max-width: 97.75rem;
  margin: 0 auto;

  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`

const Left = styled.div`
  width: 50%;
  margin-right: 9.0625rem;

  @media (max-width: 1600px) {
    margin-right: 9.063vw;
  }

  @media (max-width: 1199px) {
    width: 100%;
    margin-right: 0;
  }
`

const Right = styled.div`
  width: 50%;
  margin-top: 1.875rem;

  @media (max-width: 1199px) {
    width: 100%;
    max-width: 100%;
    margin-top: 1.375rem;
  }
`

const Claim = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: bold;
  font-size: 0.625rem;
  letter-spacing: 0.1em;
  line-height: 1.6875rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 2.8125rem;
  letter-spacing: 0.02em;
  line-height: 3.4375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    max-width: 43.75rem;
    margin: 0 auto;
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;

  @media (max-width: 1199px) {
    max-width: 39.375rem;
    margin: 0 auto;
  }
`
