import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  className?: string
  image?: ImageProps
  languageCode: string
  label?: string
  subtitle?: string
  title?: string
  URL: string
  externalURL?: string
}

export const Item = memo(function Item({
  className,
  image,
  languageCode,
  label,
  subtitle,
  title,
  URL,
  externalURL,
}: Props) {
  const componentDOM = (
    <>
      <Background>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </Background>
      <Inner dial={4}>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {subtitle ? (
          <FadeInUp>
            <Subtitle>{subtitle}</Subtitle>
          </FadeInUp>
        ) : null}
        <FadeInUp>
          <CTA
            label={useVocabularyData('find-out-more', languageCode)}
            noActions
          />
        </FadeInUp>
      </Inner>
    </>
  )

  return (
    <>
      {externalURL ? (
        <ExternalLink
          className={className}
          href={externalURL}
          target="_blank"
          rel="noopener"
        >
          {componentDOM}
        </ExternalLink>
      ) : (
        <Container className={className} to={URL}>
          {componentDOM}
        </Container>
      )}
    </>
  )
})

const Style = css`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
  height: 100%;
`

const ExternalLink = styled.a`
  ${Style}
`

const Container = styled(Link)`
  ${Style}
`

const Background = styled.div`
  width: 54.5%;
  overflow: hidden;
  padding-bottom: 40%;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 992px) {
    width: 100%;
    padding-bottom: 60%;
  }

  @media (max-width: 768px) {
    padding-bottom: 65%;
  }
`

const Inner = styled(FlexBox)`
  width: 45.5%;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 0 4.563vw;

  @media (max-width: 992px) {
    width: 100%;
    min-height: 18.75rem;
    padding: 2.5rem;
  }

  @media (max-width: 768px) {
    padding: 2.0625rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: bold;
  font-size: 0.625rem;
  letter-spacing: 0.1em;
  line-height: 1.6875rem;
  text-transform: uppercase;
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 2.1875rem;
  letter-spacing: 0.02em;
  line-height: 2.4375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }

  @media (max-width: 768px) {
    font-size: 1.5625rem;
  }
`

const Subtitle = styled.div`
  margin-top: 1.375rem;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;

  @media (max-width: 1199px) {
    margin-top: 1.125rem;
  }
`

const CTA = styled(Button)`
  margin-top: 2.5rem;
`
