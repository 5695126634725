import styled from '@emotion/styled'
import { FadeIn } from 'app/components/Common/Animation/FadeIn'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  className?: string
  image?: ImageProps
  style?: any
  title?: string
}

export const Item = memo(function Item({ className, style, title }: Props) {
  if (!title) {
    return null
  }

  return (
    <Container row dial={5} className={className} style={style}>
      <FadeIn>
        <Title>{title}</Title>
      </FadeIn>
    </Container>
  )
})

const Title = styled.div`
  width: 40vw;
  padding: 0 9.375vw;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 2.8125rem;
  letter-spacing: 0.02em;
  line-height: 3.4375rem;
  text-align: center;
  text-transform: uppercase;
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;

  @media (max-width: 1920px) {
    width: 38.75rem;
    padding: 0 4.375rem;
  }

  @media (max-width: 1440px) {
    width: 40vw;
    padding: 0 9.375vw;
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }

  @media (max-width: 1199px) {
    width: 100%;
    max-width: 31.25rem;
    padding: 0;
  }

  @media (max-width: 768px) {
    max-width: 18.75rem;
  }

  @media (max-width: 480px) {
    max-width: 12.5rem;
  }
`

const Container = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.primaryLight};

  &.active ${Title} {
    opacity: 1;
  }

  &:before,
  &:after {
    content: '';
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight};
    opacity: 0.6;
    flex: 1;

    @media (max-width: 1199px) {
      display: none;
    }
  }
`
