import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

interface ImagesProps {
  image?: ImageProps
}

export interface Props {
  cta?: ButtonProps
  label?: string
  images?: ImagesProps[]
  title?: string
}

export const GalleryPreview = memo(function galleryPreview({
  cta,
  label,
  images,
  title,
}: Props) {
  if (!images) {
    return null
  }
  if (images.length < 1) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        <Items>
          <Inner>
            {label ? (
              <FadeInUp>
                <Label>{label}</Label>
              </FadeInUp>
            ) : null}
            {title ? (
              <FadeInUp>
                <Title dangerouslySetInnerHTML={{ __html: title }} />
              </FadeInUp>
            ) : null}
          </Inner>
          {images.map((item, index) => (
            <LazyLoadComponent key={index}>
              <StyledImage {...item.image} />
            </LazyLoadComponent>
          ))}
        </Items>
        {cta ? (
          <FadeInUp>
            <StyledButton {...cta} />
          </FadeInUp>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 11.125vw 0;

  @media (max-width: 1199px) {
    margin: 5rem 1.5625rem 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 97.75rem;
  margin: 0 auto;
`

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 1.25rem);
  margin-left: -1.25rem;
  margin-bottom: -1.25rem;

  @media (max-width: 992px) {
    width: 100%;
    margin-left: 0;
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(33.3% - 1.25rem);
  height: 50%;
  margin-left: 1.25rem;
  margin-bottom: 1.25rem;
  aspect-ratio: 400/180;

  @media (max-width: 992px) {
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-left: 0;
    margin-bottom: 1.875rem;
    aspect-ratio: auto;
    text-align: center;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: bold;
  font-size: 0.625rem;
  letter-spacing: 0.1em;
  line-height: 1.6875rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 2.8125rem;
  letter-spacing: 0.02em;
  line-height: 3.4375rem;
  text-transform: uppercase;

  @media (max-width: 1480px) {
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  width: calc(33.3% - 1.25rem);
  margin-left: 1.25rem;
  margin-bottom: 1.25rem;

  @media (max-width: 992px) {
    width: 100%;
    margin-left: 0;
  }

  &:nth-of-type(1) {
    aspect-ratio: 1;

    @media (max-width: 992px) {
      max-height: 34.375rem;
      order: 2;
    }
  }

  &:nth-of-type(2) {
    height: 50%;
    aspect-ratio: 400/180;

    @media (max-width: 992px) {
      max-height: 100%;
      order: 3;
    }
  }

  &:nth-of-type(3) {
    margin-top: -17.5%;
    aspect-ratio: 1;

    @media (max-width: 992px) {
      max-height: 34.375rem;
      margin-top: 0;
      order: 4;
    }
  }

  &:nth-of-type(4) {
    height: 50%;
    aspect-ratio: 400/180;

    @media (max-width: 992px) {
      max-height: 100%;
      order: 5;
    }
  }

  &:nth-of-type(5) {
    margin-top: -17.5%;
    aspect-ratio: 1;

    @media (max-width: 992px) {
      max-height: 34.375rem;
      margin-top: 0;
      order: 6;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const StyledButton = styled(Button)`
  margin-top: 4.375rem;

  @media (max-width: 1199px) {
    margin-top: 2.8125rem;
  }
`
